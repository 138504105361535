<template>
  <div id="app">
    <div id="nav">
      <v-app-bar color="orange lighten-5" dark>
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
        <v-toolbar-side-icon>
          <v-img
            contain
            max-height="50"
            max-width="50"
            alt="stress-NL logo"
            src="./assets/logo.png"
            height="35"
            style="margin-right: 12px; margin-top:6px;"
          >
          </v-img>
        </v-toolbar-side-icon>
        <v-toolbar-title>Stress-EU Database</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-img
          contain
          max-height="50"
          max-width="150"
          alt="stress-NL logo"
          src="./assets/stress-eu-logo.png"
          style="margin:0 18px;"
        >
        </v-img>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list nav dense>
          <v-list-item-group v-model="group" active-class="text--accent-4">
            <v-list-item>
              <v-list-item-icon class="mr-menu">
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <router-link to="/">Home</router-link>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon class="mr-menu">
                <v-icon>mdi-information</v-icon>
              </v-list-item-icon>
              <router-link to="/about">About</router-link>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon class="mr-menu">
                <v-icon>mdi-database</v-icon>
              </v-list-item-icon>
              <router-link to="/data">Data</router-link>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon class="mr-menu">
                <v-icon>mdi-email-plus</v-icon>
              </v-list-item-icon>
              <router-link to="/request_data">Request Data</router-link>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon class="mr-menu">
                <v-icon>mdi-cloud-upload</v-icon>
              </v-list-item-icon>
              <router-link to="/upload">Contribute Data</router-link>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon class="mr-menu">
                <v-icon>mdi-file-document-check</v-icon>
              </v-list-item-icon>
              <router-link to="/plans">Approved Analysis Plans</router-link>
            </v-list-item>

            <div
              v-if="
                this.$store.state.userProfile &&
                  this.$store.state.userProfile.id
              "
            >
              <v-list-item>
                <v-list-item-icon class="mr-menu">
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <router-link to="/admin">Admin</router-link>
              </v-list-item>
            </div>

            <v-list-item
              v-if="
                this.$store.state.userProfile &&
                  this.$store.state.userProfile.id
              "
            >
              <v-list-item-icon class="mr-menu">
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <!-- <div v-if="this.$store.state.userProfile && this.$store.state.userProfile.id"> -->
              <router-link to="/logout">Logout</router-link>
              <!-- </div> -->
              <!-- <div v-else>
              <router-link to="/login">Login</router-link>
            </div> -->
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
  }),
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.mr-menu {
  margin-right: 12px !important;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'

Vue.config.productionTip = false

Vue.filter('formatDate', function(value) {
  if (value) {
    return new Date(value).toLocaleDateString();
  }
});

Vue.filter('sortTruncateList', function(value, length, clamp) {
  if (value) {
    const number_list = value.map(x => {
      return Number(x);
    });
    number_list.sort(function(a, b){return a-b});
    return number_list.join(", ").length > length ? number_list.join(", ").slice(0, length) + clamp : number_list.join(", ");
  }
});


new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue';
import Vuex from 'vuex';

import router from '../router';
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loadingData: true, 
    userProfile: {
    },
    studies: [],
    participants: [],
    participants_summary: [],
    requests: [],
  },
  getters: {
    isLoggedIn: state => Boolean(state.userProfile && state.userProfile.id)
  },
  mutations: {
    setLoadingData(state, val) {
      state.loadingData = val;
    },
    setUserProfile(state, val) {
      state.userProfile = val;
    },
    setStudies(state, val) {
      state.studies = val;
    },
    setParticipants(state, val) {
      state.participants = val;
    },
    setParticipantsSummary(state, val) {
      state.participants_summary = val; 
    }, 
    setRequests(state, val) {
      state.requests = val;
    },
  },
  actions: {
    async loginUser({ dispatch }, form) {

      const data = {    
        email: form.email,    
        password: form.password    
      }    

      axios.post("/api/login", data)    
        .then(() => {    

            dispatch('fetchUserProfile');   

            router.push("/");  
        })    
        .catch((errors) => {    
            console.log("Cannot log in: ", errors);
        });
      
    },
    async logoutUser({commit}) {
      axios
        .get("/api/logout")
        .then(() => {
          commit('setUserProfile', {});
          router.push("/login");
        })
    },
    async fetchUserProfile({commit}){

      axios.get("/api/user")    
        .then((response) => {    
          commit('setUserProfile', response.data.user);
        })    
        .catch((errors) => {    
          console.log("error getting user profile: ", errors);  
        })    
    },
    async queryStudies({commit}, {query}) {
      commit('setLoadingData', true);
      axios 
        .post("/api/queryStudies", query)
        .then((data) => {
          commit('setStudies', data.data.studies);
          commit('setParticipants', data.data.participants);
          commit('setParticipantsSummary', data.data.participants_summary); 
          commit('setLoadingData', false);
        });
    },
    async queryRequests({commit}) {
      axios 
        .get("/api/queryRequests")
        .then((data) => {
          commit('setRequests', data.data);
        });
    },
    async deleteStudyParticipants({dispatch}, query) {
      axios 
        .post("/api/deleteStudyParticipants", query)
        .then(() => {
          dispatch('queryStudies', {query: {}});
        });
    }
  },
  modules: {
  }
})
